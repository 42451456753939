import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import { LanguageSwitcher } from '../components/LanguageSwitcher'
import { SocialIcons } from '../components/SocialIcons'
import { FormSection } from '../components/FormSection'
import { Styled } from '../styles/dinastia.styles'
import { graphql } from 'gatsby'
import { useTranslation } from 'gatsby-plugin-react-i18next'

const DinastiaPage = () => {
  const { t } = useTranslation()

  return (
    <Layout>
      <SEO
        title="Dinastia"
        description="A Dinastia é uma promotora imobiliária com investimentos localizados junto à Universidade de Aveiro, focada num estilo de arquitetura direcionada para jovens e estudantes universitários."
      />
      <Styled.Main>
        <div
          className="intro"
          style={{ backgroundImage: `url(${t('introImg')})` }}
        >
          <LanguageSwitcher />

          <SocialIcons isColumn />
        </div>

        <section>
          <div className="initial-text text">
            <p dangerouslySetInnerHTML={{ __html: t('text1') }} />
          </div>

          <div className="content">
            <img alt="Dinastia" src={t('contentImg')} />

            <div className="text">
              <p
                className="quote"
                dangerouslySetInnerHTML={{ __html: t('quote') }}
              />
            </div>
          </div>

          <div className="final-text text">
            <p dangerouslySetInnerHTML={{ __html: t('text2') }} />
          </div>

          <div className="map">
            <iframe
              src="https://snazzymaps.com/embed/391314"
              width="100%"
              height="600px"
            ></iframe>
          </div>

          <FormSection isDinastiaPage />
        </section>
      </Styled.Main>
    </Layout>
  )
}

export default DinastiaPage

export const pageQuery = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["home", "dinastia", "global"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
