import styled from 'styled-components'

const Main = styled.main`
  position: relative;
  width: 100%;
  padding-bottom: ${({ theme }) => theme.spacingXL};

  .intro {
    position: relative;
    width: 100%;
    min-height: 100vh;
    background-size: cover;
    background-position: center center;

    .language-switcher {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: ${({ theme }) => theme.spacingS};
    }

    .social-icons {
      position: absolute;
      bottom: ${({ theme }) => theme.spacingS};
      right: ${({ theme }) => theme.spacingS};
    }
  }

  .initial-text,
  .final-text {
    margin-top: ${({ theme }) => theme.spacingXL};
    text-align: center;
  }

  .final-text {
    margin-top: ${({ theme }) => theme.spacingM};
  }

  .content {
    display: flex;
    align-items: center;
    margin-top: ${({ theme }) => theme.spacingL};
    gap: ${({ theme }) => theme.spacingM};

    img {
      width: 50%;
      object-fit: contain;
    }

    .text {
      width: 50%;
    }
  }

  .map {
    margin: ${({ theme }) => theme.spacingXL} 0 ${({ theme }) => theme.spacingM};

    iframe {
      border: none;
    }
  }

  @media screen and (max-width: 900px) {
    .intro {
      min-height: 50vh;
    }

    .initial-text,
    .final-text,
    .content {
      padding: 0 ${({ theme }) => theme.spacingS};
    }

    .initial-text {
      margin-top: ${({ theme }) => theme.spacingL};
    }

    .content {
      flex-direction: column;

      img,
      .text {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 475px) {
    .intro {
      min-height: 35vh;
    }

    .map iframe {
      height: 400px;
    }
  }
`

export const Styled = {
  Main,
}
